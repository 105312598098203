.skills-container{
    padding: 1rem;
    color: white;
}

.skills-heading{
    text-align: center;
    margin: 70px;
    font-size: 50px;
    color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
    }

    .skills-heading2{
        text-align: center;
        margin: 20px;
        font-size: 25px;
        color: transparent;
      font-family: Arial, Helvetica, sans-serif;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
        }

.skill-content{
    display: flex;
    flex-direction: row;
    gap: 4rem;
    justify-content: center;
}

.skill-slide{
    border: 1px solid goldenrod;
}

.skill-card-content{
    text-align: center;
    background: black;
}

.skill-card-content>:nth-child(1){
    text-align: center;
    color: white;
}

.skill-card-content>:nth-child(2){
    text-align: center;
    color: white;
}

.skill-card-content>:nth-child(3){
    text-align: center;
    color: white;
}

@media screen and (max-width: 768px){
    .skills-container{
        padding: 2rem;
    }

    .skills-heading{
        font-size: 35px;
    }

    .skill-content{
        display: flex;
        flex-direction: column;
    }

    .skills-slide{
        width: fit-content;
        height: fit-content;
        
    }

}