.contact-container{
    padding: 1rem;
}

.contact-right{
    display: flex;
    color: white;
    justify-content: center;
    margin: 10px;
}

.contact-icon {
    display: inline-block;
    margin-right: 10px;
    font-size: 24px; /* Adjust the size as needed */
  }
  
  .contact-icon a {
    color: #333; /* Change the color as needed */
  }
  
  .contact-icon a:hover {
    color: #71777c; /* Change the hover color as needed */
  }

.contact-form{
    justify-content: center;
    text-align: center;
    display: flex;
    color: white;
    
}

.contact-heading{
    text-align: center;
    margin: 70px;
    font-size: 50px;
    color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
    
}

.contact-textfield{
    background: white;
    color: white;
}

.contact-btn{
    justify-content: center;
    text-align: center;
    display: flex;
}

@media screen and (max-width: 768px){
    .contact-container{
        padding: 2rem;
    }

    .contact-heading{
        font-size: 35px;
    }

    .contact-right{
        display: flex;
        flex-direction: row;
    }

}