.educations-container{
    /* display: flex; */
    padding: 1rem;
    color: white;
    margin-top: 680px;
}

.educations-heading{
    text-align: center;
    margin: 70px;
    font-size: 50px;
    color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
    }

.education-content{
    display: flex;
    flex-direction: row;
    gap: 4rem;
    height:150px;
    justify-content: center;
}

.education-slide{
    border: 1px solid goldenrod;
}

.education-card-content{
    text-align: center;
    background: black;
}

.education-card-content>:nth-child(1){
    text-align: center;
    color: white;
}

.education-card-content>:nth-child(2){
    text-align: center;
    color: white;
}

.education-card-content>:nth-child(3){
    text-align: center;
    color: white;
}

.education-card-content>:nth-child(3){
    text-align: center;
    color: white;
}

@media screen and (max-width: 768px){
    .educations-container{
        padding: 2rem;
    }

    .educations-heading{
        font-size: 35px;
    }

    .education-content{
     display: flex;
     flex-direction: column;
     width: fit-content;
     height: fit-content;
    }
}