.hero-container{
    display: flex;
    margin-bottom: 132px;
    color: white;
}

.hero-left{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 4rem;
}

.hero-left>:nth-child(1){
    font-weight: bold;
    font-size: 70px;
}

.hero-left>:nth-child(2){
    font-weight: bold;
    font-size: 50px;
}

.hero-left>:nth-child(3){
    font-weight: bold;
    font-size: 25px;
    margin: 10px;
}

.btn-hireme{
    font-weight: normal;
    color: white;
}

.btn-hireme:hover{
    background: goldenrod;
}

.hero-right{
    display: flex;
    flex-direction: column;
    font-size: 50px;
}

.bluecircle{
    width: 40rem;
    height: 25rem;
    border-radius: 0%;
    border: 11px solid goldenrod;
    z-index: 1;
    position: absolute;
    bottom: 0;
    /* background: linear-gradient(135.74deg, #0bb5ff 14.09%, #6dd3fe 83.81%); */
}

.bluecircle1{
    width: 43rem;
    height: 35rem;
    border-radius: 50%;
    /*border: 11px solid goldenrod;
    */z-index: 1;
    position: absolute;
    bottom: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@media screen and (max-width: 768px){
    .hero-container{  
     font-size: small;
     margin-bottom: 0px;
    }

    .hero-left{
        padding: 2rem;
        margin-top: 280px;
    }

    .bluecircle{  
     display: none;
    }

    .bluecircle1{
            width: 64%;
            height: 28%;
            border-radius: 86%;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            -webkit-border-radius: 86%;
            -moz-border-radius: 86%;
            -ms-border-radius: 86%;
            -o-border-radius: 86%;
}

    .shape-overlay {
        position: absolute;
        bottom: 2;
        left: 0;
        width: 100%;
        height: 100%;
   
       
        z-index: 1;
      }  
}
.shape-overlay {
    position: absolute;
    bottom: 2;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135.74deg, rgb(90, 65, 2) 14.09%, #000000 83.81%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 86%, 66% 100%, 0% 100%);
    z-index: 1;
  }  
  
  