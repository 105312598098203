.careers-container{
    /* display: flex; */
    padding: 1rem;
    color: white;
}

.careers-heading{
    text-align: center;
    margin: 70px;
    font-size: 50px;
    color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
    }

.career-content{
    display: flex;
    flex-direction: row;
    gap: 4rem;
    justify-content: center;
}

.career-slide{
    border: 1px solid goldenrod;
}

.career-card-content{
    text-align: center;
    background: black;
    height: 80px;
}

.career-card-content>:nth-child(1){
    text-align: center;
    color: white;
}

.career-card-content>:nth-child(2){
    text-align: center;
    color: white;
}

.career-card-content>:nth-child(3){
    text-align: center;
    color: white;
}

.career-card-content>:nth-child(4){
    text-align: center;
    color: white;
}

@media screen and (max-width: 768px){
    .career-container{
        padding: 2rem;
    }

    .careers-heading{
        font-size: 35px;
    }

    .career-content{
        display: flex;
        flex-direction: column;
        font-size: 25px;
    }

}