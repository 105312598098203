.header-container{
    display: flex;
    justify-content: center;
    color: white;
    /* padding: 11rem; */
}

.header-list{
    display: flex;
    gap: 2rem;
    list-style: none;
}

.header-list>li:nth-child(1):hover{
    color: goldenrod;
}

.header-list>li:nth-child(2):hover{
    color: goldenrod;
}

.header-list>li:nth-child(3):hover{
    color: goldenrod;
}

.header-list>li:nth-child(4):hover{
    color: goldenrod;
}

.header-list>li:nth-child(5):hover{
    color: goldenrod;
}

.blog-link {
    text-decoration: none;
    color: white;
  }
  
  .blog-link:hover {
    color: goldenrod;
  }

@media screen and (max-width: 768px){
    .header-container>:nth-child(1){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }

    .header-list{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
}