.projects-container{
    padding: 1rem;
    color: white;
    margin-top: -100px;
}

.projects-heading{
    text-align: center;
    margin-top: 200px;
    margin-bottom: 70px;
    margin-left: 70px;
    margin-right: 70px;
    font-size: 50px;
    color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
    }

    .projects-heading2{
        text-align: center;
        margin: 20px;
        font-size: 25px;
        color: transparent;
      font-family: Arial, Helvetica, sans-serif;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
        }

.project-content{
    display: flex;
    flex-direction: row;
    gap: 4rem;
    justify-content: center;
}

.project-slide{
    border: 1px solid goldenrod;
}

.project-card-content{
    text-align: center;
    background: black;
}

.project-card-content>:nth-child(1){
    text-align: center;
    color: white;
}

.project-card-content>:nth-child(2){
    text-align: center;
    color: white;
}

.project-card-content>:nth-child(3){
    text-align: center;
    color: white;
}

@media screen and (max-width: 768px){
    .projects-container{
        padding: 2rem;
    }

    .projects-heading{
        font-size: 35px;
    }

    .project-content{
        display: flex;
        flex-direction: column;
    }

    .project-slide{
        width: fit-content;
        height: fit-content;
        
    }

}